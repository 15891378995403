.pressure-container {
  grid-column: 1 / span 2;
  grid-row: 2;
  height: 100%;
  width: 100%;
  border: 1px solid #434955;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .pressure-gauge-icon-container {
    position: relative;
  }

  .pressure-label-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 38px;

    .pressure-label {
      font-weight: 500;
      font-size: 24px;
      text-transform: uppercase;
      color: #afbdde;
    }

    .info-tip {
      margin-left: 5px;
    }
  }

  .pressure-gauge-container {
    position: relative;

    .pressure-value-container {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0.75;

      .pressure-value {
        font-weight: 600;
        font-size: 56px;
        color: #ffffff;
      }
    }

    .tempereature-gauge-needle {
      position: absolute;
      top: 0;
      left: 22px;
    }
  }

  .set-limits-container {
    .set-limits-label {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.47px;
      color: #696f7d;
    }

    .set-limits-value {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.47px;
      color: #ffffff;
    }
  }

  .set-limits-container-sm {
    display: none;
  }

  &.warning {
    .pressure-gauge-container {
      .pressure-value-container {
        .pressure-value {
          color: #ff3b30;
        }
      }
    }

    .pressure-gauge-scale {
      stroke: #ff3b30;
    }
  }
}

@media screen and (max-width: 576px) {
  .pressure-container {
    flex-direction: column;

    .pressure-description {
      margin-top: 20px;

      .pressure-label-container {
        margin-bottom: 0;
      }

      .set-limits-container {
        margin-top: 20px;
        display: none;
      }
    }

    .pressure-gauge-container {
      margin-top: 20px;
      transform: scale(0.8);
    }

    .set-limits-container-sm {
      margin-top: 20px;
      margin-bottom: 20px;
      display: block;
    }
  }
}
