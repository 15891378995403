.telemetry-container {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5px;
  background-color: #141b2a;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 350px 220px 220px;
  gap: 37px 30px;
  overflow-y: hidden;
}

@media screen and (max-width: 576px) {
  .telemetry-container {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
  }
}
