.devices-table {
  padding: 30px 30px 5px 30px;
  display: flex;
  flex-direction: column;
  color: white;

  .devices-table__title {
    display: flex;
    align-items: center;

    .devices-table__devices-length {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 20px;
      margin-left: 5px;
      border-radius: 50%;
      background: #2777d6;
    }
  }

  .devices-table__devices-list {
    padding: 0px;

    .devices-table__device-link {
      text-decoration: none;
      color: #fff;

      li {
        padding: 15px;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);

        &:hover {
          background: rgba($color: #fff, $alpha: 0.2);
        }
      }
    }
  }

  .devices-table__empty-list {
    color: #fff;
    text-align: center;
    margin-top: 75px;
  }
}
