@mixin displayFlex() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.bluetooth-modal {
  @include displayFlex();
  margin-top: 0;
  padding: 30px;
  background-color: var(--bs-body-color);
  color: white;

  .bluetooth-modal__QR {
    @include displayFlex();

    .bluetooth-modal__QR-reader {
      margin: 0 auto;
      width: 40%;
    }
  }
}
