$yellow: #fbff00;
$red: #ff0000;
$green: #3cff00;

@mixin device-btn($color) {
  color: rgba($color, 1);
  border: 1px solid rgba($color, 1);
  width: 30px;
  height: 30px;
  background: none;
  border-radius: 50%;
  outline: none;
}
@mixin device-btn__hover($color) {
  color: #fff;
  background-color: rgba($color, 0.4);
  border-color: rgba($color, 0.7);
}
@mixin delist-method($radius) {
  height: 50px;
  width: 50px;
  margin-left: 10px;
  color: #fff;
  cursor: pointer;
  background: #2777d6;
  border: none;
  border-radius: $radius;
}
.device {
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    gap: 15px;

    .device-btn__settings {
      @include device-btn($yellow);

      &:hover {
        @include device-btn__hover($yellow);
      }
    }
    .device-btn__delete {
      @include device-btn($red);

      &:hover {
        @include device-btn__hover($red);
      }
    }
    .device-btn__edit {
      @include device-btn($green);

      &:hover {
        @include device-btn__hover($green);
      }
    }
    span {
      justify-content: right;
    }
  }
}

.list-method {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .list-method__add {
    @include delist-method(50%);

    .icon-plus {
      font-size: 32px;
    }
  }
  .list-method__scan-qr {
    @include delist-method(10px);

    .icon-qr-code {
      font-size: 32px;
    }
  }
}
