.humidity-container {
  height: 100%;
  width: 100%;
  border: 1px solid #434955;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .humidity-gauge-icon-container {
    position: relative;
  }

  .humidity-label-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .humidity-label {
      font-weight: 500;
      font-size: 24px;
      text-transform: uppercase;
      color: #afbdde;
    }

    .info-tip {
      margin-left: 5px;
    }
  }

  .humidity-gauge-container {
    position: relative;
    transform: scale(0.9);

    .humidity-value-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0.75;

      .humidity-sign {
        font-weight: 600;
        font-size: 26px;
        color: #ffffff;
      }

      .humidity-value {
        font-weight: 600;
        font-size: 56px;
        color: #ffffff;
      }

      .humidity-unit {
        align-self: flex-start;
        font-weight: 600;
        font-size: 26px;
        color: #ffffff;
      }
    }
  }

  .set-limits-container {
    margin-bottom: 20px;

    .set-limits-label {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.47px;
      color: #696f7d;
    }

    .set-limits-value {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.47px;
      color: #ffffff;
    }
  }

  &.warning {
    .humidity-gauge-container {
      .humidity-value-container {
        .humidity-value,
        .humidity-unit {
          color: #ff3b30;
        }
      }
    }

    .humidity-oval {
      stroke: #ff3b30;
    }
  }
}
