.add-device-modal-form {

  .add-device-modal-form__input {
    outline: none;
    margin-top: -5px;
    color: #fff;
    padding: 5px 10px;
    width: 80%;
    border: none;
    border-bottom: 1px solid rgb(122, 122, 155);
    background: transparent;
    margin: 20px, auto;
    text-align: left;
    
    &:active,
    &:focus {
      border-bottom: 1px solid #ffffff;
    }
  }

  .add-device-modal-form__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 200px;
    width: 300px;
    margin: 0 auto;
    margin-top: 30px;
  }
}
