@mixin displayFlex() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.settings-modal-form {
  @include displayFlex();

  .settings-modal-form__sliders {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .settings-modal-form__buttons {
    @include displayFlex();
    height: 200px;
    width: 300px;
    margin: 0 auto;
    margin-top: 30px;
  }
}
