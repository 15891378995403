@mixin displayFlex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-internet-connection {
  @include displayFlex();
  background-color: #141b2a;

  padding: 0px 45px;
  height: 100vh;

  .no-internet-connection__warning-container {
    @include displayFlex();
    background-color: #1f2634;

    flex-direction: column;
    border-radius: 8px;
    width: 100%;
    padding: 73px 60px 80px;

    .no-internet-connection__header {
      font-size: 56px;
      font-weight: 600;
      letter-spacing: 0.41px;
      line-height: 52px;
      color: #fff;
      text-align: center;
      margin-top: 60px;
    }

    .no-internet-connection__description {
      font-size: 34px;
      font-weight: 600;
      letter-spacing: -0.82px;
      line-height: 44px;
      color: #696f7d;
      text-align: center;
      margin-top: 40px;
    }
  }
}
