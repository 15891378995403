.input-error {
    color: #bf1650;
    margin-top: 5px;
    padding-left: 50px;
    text-align: left;
    font-size: 12px;
}

.modal-form-name {
    margin-top: 10px;
    padding-left: 50px;
    text-align: left;
}
