.slider {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 220px;
  width: 100%;
  max-width: 300px;
  margin: 20px;
  border-bottom: 1px dotted rgb(63, 92, 225);
  border-radius: 20px;
  .slider__name {
      color: rgb(170, 101, 226);
      font-size: 20px;
      text-transform: capitalize;
  }
  .slider__limit {
      color: rgb(141, 247, 43);
      font-size: 13px;
  }
}
@media (max-width: 991px) {
  .slider {
    max-width: 100% !important;
  }
}
@media (max-width: 991px) {
    .slider {
      max-width: 100% !important;
    }
  }
  