@mixin displayFlex() {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}

.home-page {
    @include displayFlex();
    padding: 50px;

    .home-page__description {
        margin-bottom: 100px;
        margin-top: 150px;
        font-weight: 600;
        letter-spacing: 0.47px;
    }
    .home-page__notify-btn {
        @include displayFlex();
        text-decoration: none;
        justify-content: center;
        
        width: 80%;
        min-width: 250px;
        height: 4rem;
        border-radius: 2.8rem;
        background-color: #0065dd;
        font-size: 1.8rem;
        cursor: pointer;

        &:hover {
            background: linear-gradient(to left, #79f1a4 0%, #2a81e3 100%);
        }
    }
}