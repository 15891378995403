.custom-btn {
  height: 50px;
  margin-bottom: 30px;
  color: #fff;

  border: 1px solid gray;
  background-color: var(--bs-body-color);
  border-radius: 2.8rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.save-btn {
  height: 50px;
  margin-bottom: 30px;
  color: #fff;

  border: none;
  background-color: #0073ff;
  border-radius: 2.8rem;

  &:hover {
    background: linear-gradient(to left, #79f1a4 0%, #2a81e3 100%);
  }
}
