.tvoc-container {
  height: 100%;
  width: 100%;
  border: 1px solid #434955;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .tvoc-gauge-icon-container {
    position: relative;
  }

  .tvoc-label-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .tvoc-label {
      font-weight: 500;
      font-size: 24px;
      text-transform: uppercase;
      color: #afbdde;
    }

    .info-tip {
      margin-left: 5px;
    }
  }

  .tvoc-value-container {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0.75;

    .tvoc-value {
      font-weight: 600;
      font-size: 56px;
      color: #ffffff;
      line-height: 67px;
    }
  }

  .tvoc-gauge-container {
    position: relative;
    min-height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 28px;

    .tvoc-gauge-needle {
      position: absolute;
      justify-self: flex-start;
    }
  }

  .set-limits-container {
    margin-bottom: 20px;

    .set-limits-label {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.47px;
      color: #696f7d;
    }

    .set-limits-value {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.47px;
      color: #ffffff;
    }
  }

  &.warning {
    .tvoc-value-container {
      .tvoc-value {
        color: #ff3b30;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .tvoc-container {
    .tvoc-gauge-container {
      transform: scale(0.9);
    }
  }
}