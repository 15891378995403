.top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 29px 0;
  background-color: #1f2634;
}

@media screen and (max-width: 576px) {
  .top-bar {
    padding: 20px 0;
  }
}
