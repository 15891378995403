@mixin displayFlex($justify) {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: $justify;
}

.add-device-modal {
  @include displayFlex(space-between);
  background-color: var(--bs-body-color);
  color: #fff;

  .add-device-modal__QR {
    @include displayFlex(center);

    .add-device-modal__QR-title {
      padding-top: 30px;
      padding-bottom: 20px;
    }
    .add-device-modal__QR-reader {
      margin: 0 auto;
      width: 40%;
    }
  }

  .add-device-modal__description {
    @include displayFlex(center);
    height: 200px;

    .add-device-modal__description-title {
      padding-bottom: 30px;
    }

    .add-device-modal__description-text {
      opacity: 0.5;
    }
  }
}
