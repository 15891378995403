.temperature-container {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #434955;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .temperature-gauge-icon-container {
    position: relative;
  }

  .temperature-label-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .temperature-label {
      font-weight: 500;
      font-size: 24px;
      text-transform: uppercase;
      color: #afbdde;
    }

    .info-tip {
      margin-left: 5px;
    }
  }

  .temperature-gauge-container {
    position: relative;
    transform: scale(0.9);

    .tempereature-gauge-scale {
      .minus-plus {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 27px;
        color: #434955;
      }
    }

    .temperature-value-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0.75;

      .temperature-sign {
        font-weight: 600;
        font-size: 26px;
        color: #ffffff;
      }

      .temperature-value {
        font-weight: 600;
        font-size: 56px;
        color: #ffffff;
      }

      .temperature-unit {
        align-self: flex-start;
        font-weight: 600;
        font-size: 26px;
        color: #ffffff;
      }
    }
  }

  .temperature-gauge-needle {
    position: absolute;
    top: -3px;
    left: 0;
  }

  .set-limits-container {
    margin-bottom: 20px;

    .set-limits-label {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.47px;
      color: #696f7d;
    }

    .set-limits-value {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.47px;
      color: #ffffff;
    }
  }

  &.warning {
    .temperature-gauge-container {
      .temperature-value-container {
        .temperature-sign,
        .temperature-value,
        .temperature-unit {
          color: #ff3b30;
        }
      }
    }

    .temperature-gauge-scale {
      stroke: #ff3b30;
    }
  }
}
